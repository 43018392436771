<template>
  <div id="statistic-analysis">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
        :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
        @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>统计分析</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true">
          <el-form-item label="场站名称">
            <el-select v-model="requestParam.stationNumber" :popper-append-to-body="false" clearable
              placeholder="请选择场站名称" filterable  @change="changeName">
              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName+' '+item.stationNumber"
                :value="item.stationNumber"></el-option>
            </el-select>
          </el-form-item>
          <span>
            <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
          </span>
        </el-form>
      </div>
      <div class="station-name">{{ stationName }}{{requestParam.stationNumber }}</div>
      <div class="real-time-electricity">
        <div class="echarts-title">
          <img src="../../assets/images/titleT.png" alt="" />
          <div class="">总用电量实时情况统计</div>
        </div>
        <div id="nowElectricity"></div>
      </div>
      <div class="chart-content">
        <div class="two-chart">
          <div class="chart-style">
            <div class="analysis">
              <div class="echarts-title">
                <img src="../../assets/images/titleT.png" alt="" />
                <div class="">用电量统计</div>
              </div>
              <div class="form">
                <el-date-picker :append-to-body="false" v-model="dayElectricity" type="date" placeholder="日" value-format="yyyy-MM-dd"
                  :clearable="false" @change="queryElectricityStatistic"></el-date-picker>
              </div>
            </div>
            <div id="electricityStatistic" class="statistics-echarts"></div>
          </div>
          <div class="chart-style">
            <div class="analysis">
              <div class="echarts-title">
                <img src="../../assets/images/titleT.png" alt="" />
                <div class="">用电量同比分析</div>
              </div>
              <div class="form">
                <el-date-picker :append-to-body="false" v-model="yearOnYear" type="date" placeholder="日" value-format="yyyy-MM-dd"
                  :clearable="false" @change="queryElectricityYOY"></el-date-picker>
              </div>
            </div>
            <div id="electricityYOY" class="statistics-echarts"></div>
          </div>
        </div>
      </div>
      <div class="chart-content">
        <div class="two-chart">
          <div class="chart-style">
            <div class="analysis">
              <div class="echarts-title">
                <img src="../../assets/images/titleT.png" alt="" />
                <div class="">全年碳排情况统计</div>
              </div>
            </div>
            <div id="yearCarbon" class="statistics-echarts"></div>
          </div>
          <div class="chart-style">
            <div class="analysis">
              <div class="echarts-title">
                <img src="../../assets/images/titleT.png" alt="" />
                <span class="">当月能耗分析情况统计</span>
              </div>
              <!-- <div class="form">
                <el-select @change="selectBar" filterable  v-model="type" placeholder="类型 " :popper-append-to-body="false">
                  <el-option v-for="item in typeOptions" :key="item.id" :label="item.transformerModel"
                    :value="item.transformerModel">
                  </el-option>
                </el-select>
              </div> -->
            </div>

            <div id="energyConsumption" class="statistics-echarts"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { deviceApi } from "@/api/device.js";
import { emissionsCarbonApi } from "@/api/emissionsCarbon.js";
import { energyStationApi } from "@/api/energyStation.js";
import { monitorApi } from "@/api/monitor.js";
export default {
  name: "Administration",
  data() {
    return {
      requestParam: {
        stationNumber: "",
      },
      loading: false,
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      stationData: [],
      // typeOptions: [],
      filterText: "",
      currentDate: "",
      firstDay: "",
      dayElectricity: "",
      yearOnYear: "",
      year: "",
      type: "",
      stationName: "",
    };
  },
  created() {
    this.$store.commit("increment", "统计分析");
    this.$store.commit("selectChild", "统计");
    this.$store.commit("selectChildren", "");
    this.getDate();
    // this.queryType()
  },
  mounted() {

    this.queryUnitName();
    // this.queryTypeSearch()
  },
  watch: {
    filterText(val) {
      console.log('11111111111111', val);
      this.$refs.tree.filter(val);
    },
  },
  methods: {

    changeName() {
      let abc = this.stationData.find(item => item.stationNumber === this.requestParam.stationNumber);
      this.stationName = abc.stationName

    },
    onSubmit() {
      this.queryNowElectricity();
      this.queryElectricityStatistic();
      this.queryElectricityYOY();
      this.drawEnergyConsumption()
    },
    // 获取当年
    getDate() {
      let date = new Date(); // 获取当前时间
      const year = date.getFullYear(); // 获取当前年份
      const currentMonth = date.getMonth() + 1; // 获取当前月份
      const currentDay = date.getDate(); //获取当前日
      let month = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // 获取当前月份
      let day = currentDay < 10 ? `0${currentDay}` : currentDay; //获取当前日
      this.firstDay = `${year}-01-01`;
      this.currentDate = `${year}-${month}-${day}`;
      this.dayElectricity = `${year}-${month}-${day}`;
      this.yearOnYear = `${year}-${month}-${day}`;
      this.year = year;
    },
    // 设备类型
    // queryType() {
    //   monitorApi.queryType().then((res) => {
    //     if (res.code === 200) {
    //       this.typeOptions = res.data;
    //       if (res.data.length !== 0) {
    //         this.type = res.data[0].transformerModel;
    //       }
    //     }
    //   });
    // },

    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    handleCheck(checkedNodes, event) {
      if (this.checkedNodes.id == checkedNodes.id) {
        this.checkedNodes=''
        const currentNode = this.$refs.tree.$el.querySelector('.is-current');
          if (currentNode) {
            currentNode.classList.remove('is-current');
          } 
      }else{
        this.checkedNodes = checkedNodes;

      }
      this.queryStation(this.checkedNodes.id);
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.treeData = res.data;
          this.queryStation();
          // this.queryStation(res.data[0].id);
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个
          // });
        }
      });
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId:companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
          if (res.data.length !== 0) {
            this.requestParam.stationNumber = res.data[0].stationNumber;
            this.stationName = res.data[0].stationName;
            this.queryNowElectricity();
            this.queryElectricityStatistic();
            this.queryElectricityYOY();
            this.drawEnergyConsumption()
          }
        }
      });
    },
    queryNowElectricity() {
      let requestParam = {
        endTime: this.currentDate,
        staTime: this.firstDay,
        stationNumber: this.requestParam.stationNumber,
        type: 5,
      };
      emissionsCarbonApi.statisticsApi(requestParam).then((res) => {
        if (res.code === 200) {
          let electricitySeries = [];
          let carbonSeries = [];
          const xAxisData = res.data.map((item) => {
            let date = item.ts.slice(0, 7);
            electricitySeries.push(item.dianNeng);
            carbonSeries.push(item.tanPait.toFixed(2));
            return date;
          });
          // for (const key in object) {
          //   xAxisData.push(key);
          //   if (object[key]) {
          //     seriesData.push(object[key]);
          //   } else {
          //     seriesData.push(`0`);
          //   }
          // }
          this.drawNowElectricity(xAxisData, electricitySeries);
          this.drawYearCarbon(xAxisData, carbonSeries);
        }
      });
    },
    queryElectricityStatistic() {
      let requestParam = {
        endTime: this.dayElectricity,
        staTime: this.dayElectricity,
        stationNumber: this.requestParam.stationNumber,
        type: 1,
      };
      emissionsCarbonApi.statisticsApi(requestParam).then((res) => {
        if (res.code === 200) {
          let seriesData = [];
          const xAxisData = res.data.map((item) => {
            let date = item.ts.slice(11, 16);
            seriesData.push(item.dianNeng);
            return date;
          });
          this.drawElectricityStatistic(xAxisData, seriesData);
        }
      });
    },
    queryElectricityYOY() {
      let requestParam = {
        stationNumber: this.requestParam.stationNumber,
        time: this.year,
      };
      emissionsCarbonApi.analysisOneApi(requestParam).then((res) => {
        if (res.code === 200) {
          let lastSeries = [];
          let nowSeries = [];
          const xAxisData = res.data.map((item) => {
            let month = item.nowTs.slice(5, 7);
            lastSeries.push(item.lastDianNeng);
            nowSeries.push(item.nowDianNeng);
            return Number(month) + "月";
          });
          this.drawElectricityYOY(xAxisData, lastSeries, nowSeries);
        }
      });
    },
    // 4.当月能耗分析情况统计
    queryEnergyConsumption() {
      let params = {
        type: 2,
        stationNumber: this.requestParam.stationNumber,
      };

      monitorApi.queryEnergyConsumption(params).then((res) => {

        // for (const key in object) {
        //   xAxisData.push(key);
        //   if (object[key]) {
        //     seriesData.push(object[key]);
        //   } else {
        //     seriesData.push(`0`);
        //   }
        // }
        if (res.data && res.data.length > 0) {

          let xAxisData = res.data.map(obj => Object.keys(obj)[0]);
          let seriesData = res.data.map(obj => Object.values(obj)[0]);
        }
        // this.setchart4(xAxisData, seriesData);
      });
    },
    drawNowElectricity(xAxisData, seriesData) {
      const nowElectricity = echarts.init(
        document.getElementById("nowElectricity")
      );
      var option = {
        tooltip: {},
        legend: {
          data: ["总用电量"],
          right: "31px",
          
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        grid: {
          left:'120px',
        },
        backgroundColor: "#071B42",
        xAxis: {
          data: xAxisData,
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
        },
        yAxis: {
          name: "用电量：kWh",
          nameTextStyle: {
            color: "#aed6ff",
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", //
            },
          },
        },
        series: [
          {
            name: "总用电量",
            type: "line",

            data: seriesData,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(42, 101, 180, .8)",
                },
                {
                  offset: 1,
                  color: "rgb(42, 101, 180, 0)",
                },
              ]),
            },
          },
        ],
      };

      nowElectricity.setOption(option);
    },
    drawElectricityStatistic(xAxisData, seriesData) {
      const electricityStatistic = echarts.init(
        document.getElementById("electricityStatistic")
      );
      var option = {
        grid: {
          top: '70',
          left: '80px',
          right:10,
          bottom: 60,
        },
        tooltip: {},
        backgroundColor: "#071B41",
        legend: {
          data: ["用电量统计"],
          right: "31px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        xAxis: {
          boundaryGap: false,
          data: xAxisData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
        },
        yAxis: {
          type: "value",
          name: "用电量：kWh",
          nameTextStyle: {
            color: "#aed6ff",
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", //
            },
          },
        },
        series: [
          {
            name: "用电量统计",
            data: seriesData,
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(20, 196, 154, .6)",
                },
                {
                  offset: 1,
                  color: "rgb(20, 196, 154, 0)",
                },
              ]),
            },
          },
        ],
      };

      electricityStatistic.setOption(option);
    },
    drawElectricityYOY(xAxisData, lastSeries, nowSeries) {
      const electricityYOY = echarts.init(
        document.getElementById("electricityYOY")
      );
      var option = {
        grid: {
          left: '100px',
        },
        tooltip: {},
        backgroundColor: "#071B41",
        legend: {
          data: ["去年", "今年"],
          right: "31px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        color: ["#2a65b4", " #2fc9cb"],
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: xAxisData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
        },
        yAxis: {
          type: "value",
          name: "用电量：kWh",
          nameTextStyle: {
            color: "#aed6ff",
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", //
            },
          },
        },
        series: [
          {
            data: lastSeries,
            type: "line",
            name: "去年",
          },
          {
            data: nowSeries,
            type: "line",
            name: "今年",
          },
        ],
      };

      electricityYOY.setOption(option);
    },
    drawYearCarbon(xAxisData, seriesData) {
      const yearCarbon = echarts.init(document.getElementById("yearCarbon"));

      var option = {
        grid: {
          top: 70,
          left: '50px',
          bottom: 60,
        },
        tooltip: {},
        legend: {
          data: ["总碳排"],
          right: "31px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        backgroundColor: "#071A40",
        xAxis: {
          data: xAxisData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
        },
        yAxis: {
          name: "单位：Kt",
          nameTextStyle: {
            color: "#aed6ff",
            fontSize: 14,
          },
          axisLabel: {
            color: "#aed6ff",
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
        },
        series: [
          {
            name: "总碳排",
            type: "bar",

            data: seriesData,
            barWidth: "20", // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: "#176AD2",
            },
          },
        ],
      };

      yearCarbon.setOption(option);
    },
    formatDate(date) {
  // 将日期格式化为 "yyyy-mm-dd" 的格式
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
},
    selectBar(){
      this.drawEnergyConsumption()
    },
    drawEnergyConsumption() {
      let obj = {
        stationNumber: this.requestParam.stationNumber,
        endTime:'',
        staTime:'',
      }
          let currentDate = new Date();
      // 获取当月第一天
      let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      // 获取当月最后一天，下个月第一天的前一天
      let lastDayOfMonth = new Date();
      obj.staTime=this.formatDate(firstDayOfMonth) 
      obj.endTime=this.formatDate(lastDayOfMonth) 

      console.log('~~~~~~~~~~~~~~', obj);
      emissionsCarbonApi.getStationPowerMonth(obj).then(res => {
        if (res.data && res.data.length > 0) {
          let xAxisData = res.data.map(item => {
            if (item.nowTime && typeof item.nowTime === 'string' && item.nowTime.length >= 10) {
              return item.nowTime.substring(0, 10);
            } else {
              return ''; // 或者您可以根据实际情况返回其他默认值
            }
          }); 
        let seriesData = res.data.map(item=>item.onceEpaddIns);

          var energyConsumption = echarts.init(document.getElementById("energyConsumption"));
          var option = {
            grid: {
              left: "2%",
              right: "1%",
              containLabel: true,
            },
            tooltip: {},
            legend: {
              data: ["能耗"],
              right: "31px",
              top: "16px",
              textStyle: {
                color: "#AED6FF",
              },
            },
            backgroundColor: "#071A40",
            xAxis: {
              data: xAxisData,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                textStyle: {
                  color: "#aed6ff", // 设置 x 轴标签字体颜色
                },
              },
            },
            yAxis: {
              name: "能耗：KWh",
              nameTextStyle: {
                color: "#aed6ff",
                fontSize: 14,
              },
              axisLabel: {
                color: "#aed6ff",
                fontSize: 14,
              },
              splitLine: {
                lineStyle: {
                  type: "dashed",
                  color: "#0b2159",
                },
              },
            },
            series: [
              {
                name: "能耗",
                type: "bar",

                data: seriesData,
                barWidth: "20", // 调整柱状图的宽度
                itemStyle: {
                  barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
                  color: " #e0ab34",
                },
              },
            ],
          };
          energyConsumption.setOption({
                    dataZoom: [
                        {
                            type: 'slider',    // 数据缩放类型为滑动条
                            start: 0,          // 开始位置（范围为 0 到 100）
                            end: 40            // 结束位置（范围为 0 到 100）
                        },
                        {
                            type: 'inside',    // 内置的数据缩放类型，鼠标滚轮缩放
                            start: 0,          // 开始位置（范围为 0 到 100）
                            end: 100            // 结束位置（范围为 0 到 100）
                        }
                        ]
                    });


          energyConsumption.setOption(option);
        }
        
      })


    },
  },

};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#statistic-analysis {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

.station-name {
  font-family: 'Arial', sans-serif;
  color: #e8f4ff;
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.el-tree {
  height: 962px;
  overflow-y: scroll;
}

.el-tree::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.el-tree::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.real-time-electricity {
  padding: 16px;
  box-sizing: border-box;
  background: #071b42;
}

.chart-content {
  margin-top: 16px;
  box-sizing: border-box;
}

.two-chart {
  display: flex;
  justify-content: space-between;
}

#nowElectricity {
  width: 100%;
  height: 260px;
}

.mycharts {
  width: 100%;
  height: 260px;
}

.titleEcharts {
  text-align: center;
  width: 100%;
  font-size: 18px;
  padding-bottom: 10px;
}

.echarts-title {
  display: flex;
  align-items: center;
}

.echarts-title span {
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  font-size: 14px;
}

.analysis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-style {
  width: 49.5%;
  background: #071b42;
  padding: 16px 0 0 16px;
  box-sizing: border-box;
}

.statistics-echarts {
  width: 100%;
  height: 300px;
}

.form {
  padding-right: 8px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
  line-height: 35px;
}
</style>
